import { useState, useEffect } from 'react'

const url_attendance = 'https://evcc-attend-api.shin.dev/attendance'
const url_getStudentByPhone = 'https://evcc-attend-api.shin.dev/getStudentsByPhone'

const AttendanceComponent = () => {
  const [students, setStudents] = useState(null)
  const [searching, setSearching] = useState(false)

  if(!getIdentifier()){
    return null
  }

  return (
    <div>
    {searching
      ? <SelectComponent students={students}/>
      : <SearchComponent setStudents={setStudents} setSearching={setSearching}/>
    }
    </div>
  )

}

const SearchComponent = (props) => {

  const findStudentByPhone = (phone) => {
    fetch(url_getStudentByPhone, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({phone:parseInt(phone), indexDate:getIdentifier()})})
    .then(response => response.json())
    .then(data => {
      console.log(data)
      if(data.length>0){
        try{
          localStorage.setItem("savedPhone", parseInt(phone))
        }catch(e){
          console.log("failed to access localStorage", e)
        }
      }
      props.setStudents(data)
    })
  }

  // custom hook to handle savedPhone once
  const useComponentDidMount = (effectOnMount) => {
    useEffect(() => {effectOnMount()},[]) // eslint-disable-line react-hooks/exhaustive-deps
  };

  // check savedPhone once
  useComponentDidMount(() => {
    try{
      const savedPhone = localStorage.getItem("savedPhone")
      if(savedPhone){
        console.log(`savedPhone: ${savedPhone}`)
        props.setSearching(true)
        findStudentByPhone(savedPhone)
      }
    }catch(e){
      console.log("failed to access localStorage", e)
    }
  },[])

  const handleChange = (event) => {
    if(event.target.value.length>=10){
      console.log("Looking for phone:", event.target.value)
      props.setSearching(true)
      findStudentByPhone(event.target.value)
    }
  }

  return (
    <div className="Search">
        <label htmlFor="phone">Enter your phone number</label>
        <input type="tel" inputMode='tel' autoComplete='tel-national' className="form-control" id="phone" placeholder="" onChange={handleChange}/>
    </div>
  )
}

const SelectComponent = (props) => {
  const reload = () => {
    try{
      localStorage.clear()
    }finally{
      window.location.reload(false)
    }
  }
  
  if(!props.students){
    return <div>Loading...</div>
  }else if(props.students.length ===0){
    return <button type="button" className="btn btn-danger" onClick={reload}>We cannot find any record. <br/> Click HERE to try again.</button>
  }else{
    const StudentComponents = props.students.map((student,id)=> 
      <StudentComponent student={student} key={id}/>
    )
    return (
      <div className='d-grid gap-3'>
        {StudentComponents}
      <br/>
      <span className="badge badge-pill badge-light" onClick={reload}>Click here to login with another phone number</span>
      </div>
    )
  }

}

const StudentComponent = (props) => {
  const buttons = props.student.classes.map((studentClass,id)=>
    <ClassButton studentClass={studentClass} student={props.student} key={id}/>
  )

  return (
    <>
      {buttons}
    </>
  )
}

const ClassButton = (props) => {
  let studentName = [props.student.firstname, props.student.middlename, props.student.lastname].join(" ")
  let studentClass = [props.studentClass.grade, props.studentClass.room, props.studentClass.time].join(" ")

  const [disabled, setDisabled] = useState(false)
  const [className, setClassName] = useState('btn btn-primary')
  const [statusMessage, setStatusMessage] = useState('')
  const [actionMessage, setActionMessage] = useState('')

  useEffect(() => {
    let attendance = null
    props.student.attendance.forEach((record)=>{
      if(record.class === props.studentClass._id){
        if(attendance===null)
          attendance=record
        if(!record.checkout)
          attendance=record
      }
    })

    if(attendance === null){
      setStatusMessage("")
      setActionMessage("Click to Check In")
    }else if(attendance.checkout){
      let checkedOutDate = new Date(attendance.checkout).toLocaleString()
      setStatusMessage(`Last Checked Out: ${checkedOutDate}` )
      setActionMessage("Click to Check In again")
    }else{
      let checkedInDate = new Date(attendance.checkin).toLocaleString()
      setStatusMessage(`Checked In: ${checkedInDate}` )
      setActionMessage("Click to Check Out")
      setClassName("btn btn-success")
    }

  }, [props.student, props.studentClass])


  const handleClick = () => {
    setDisabled(true)
    console.log(`checking: ${props.student._id}  checkid: ${getIdentifier()} classid: ${props.studentClass._id}`)
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 10000)

    fetch(url_attendance, {signal: controller.signal, method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({studentid:props.student._id, class:props.studentClass._id, indexDate:getIdentifier()})})
      .then(response => {
        clearTimeout(timeoutId)
        if(response.status === 200){
          setClassName("btn btn-light")
          setActionMessage("")
          if(actionMessage.includes('Check In')){
            setStatusMessage("Checked In: " + new Date().toLocaleString())
          }else{
            setStatusMessage("Checked Out: " + new Date().toLocaleString())
          }
        }else{
          console.log("status is not 200")
          setDisabled(false)
        }
      })
      .catch(error => {
        console.log("error: ", error)
        setDisabled(false)
      });

  }

  return  <button type="button" className={className} disabled={disabled} onClick={handleClick}> 
    {studentName} <br/> {studentClass} {statusMessage.length >0 && <br/>} {statusMessage} <br/> {actionMessage}
    </button>
}

const getIdentifier = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const identifier = params.get('i')
  if(identifier)
      return parseInt(identifier, 36)
}

export default AttendanceComponent