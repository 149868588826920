import './App.css'
import Attendance from './Attendance'

const getIdentifier = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const identifier = params.get('i')
  if(identifier)
      return parseInt(identifier, 36)
}

const Date = () => {
  const identifier = getIdentifier()
  if(identifier){
    return <h6>Date: {identifier}</h6>
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>East Valley Children's Choir</h1>
        <Date/>
        <br/>
       <Attendance/>
      </header>
    </div>
  )
}

export default App
